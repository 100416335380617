<!--
 * @Author: ChenYaJin
 * @Date: 2023-11-06 18:07:47
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-05 16:24:57
 * @Description: 垂直菜单布局
-->
<template>
  <el-scrollbar ref="verticalMenusRef" class="vertical-menus-scrollbar">
    <el-menu
      class="layouts-menu-vertical"
      :collapse-transition="false"
      :default-active="state.defaultActive"
      :collapse="config.layout.menuCollapse"
    >
      <el-menu-item
        v-for="menu in tabsViewRoutes"
        :key="menu.path"
        :index="menu.path"
        @click="onClickMenu(menu)"
      >
        <div
          :class="state.defaultActive === menu.path ? menu.icon + '-active' : menu.icon"
          class="nav-icon"
        ></div>
        <span class="ml-15px">{{ menu.title }}</span>
      </el-menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script setup lang="ts">
  import { type RouteLocationNormalizedLoaded } from 'vue-router'
  import { useConfig } from '~/stores/config'
  import { useUserStore } from '~/stores/modules/user/user'

  const config = useConfig()
  const route = useRoute()
  const userStore = useUserStore()
  const state = reactive({
    defaultActive: '',
  })
  const tabsViewRoutes = computed(() => userStore.navMenu)

  // 激活当前路由的菜单
  const currentRouteActive = (currentRoute: RouteLocationNormalizedLoaded) => {
    state.defaultActive = currentRoute.path
  }

  watch(
    () => route,
    (newValue) => {
      currentRouteActive(newValue)
    },
    { deep: true, immediate: true }
  )

  const onClickMenu = (menu: INav) => {
    state.defaultActive = menu.path as string
    navigateTo(menu.path)
  }

  onMounted(() => {
    currentRouteActive(route)
  })

  onBeforeRouteUpdate((to) => {
    currentRouteActive(to)
  })
</script>
<style scoped lang="scss">
  .vertical-menus-scrollbar {
    height: calc(100vh - 64px);
    background-color: #fff;
    z-index: 9;
  }
  .layouts-menu-vertical {
    @apply mt-24px border-0 w-auto;
  }

  .nav-icon {
    margin-left: 10px;
    width: var(--icon-small-size);
    height: var(--icon-small-size);
  }
  :deep(.el-menu-item) {
    &.is-active,
    &:hover {
      color: var(--primary-color);
      font-weight: 600;
      background-color: #f7f8f9;
    }
    &.is-active:before {
      @apply h-100% w-4px position-absolute left-0;
      background-color: var(--primary-color);
      content: '';
    }
  }
</style>
